import create from "zustand"

export const useTrainingStore = create((set) => ({
  meta: {
    id: "training",
    title: "Partnership Marketing Courses - The Partnership Economy",
    description:
      "Sign up for free partnership marketing courses and certifications with the Partnerships Experience Academy.",
  },
  title: "Access free courses from PXA",
  body: `<p>When you’re ready to dive deeper into the concepts and best practices you discover in <i>The Partnership Economy</i>, you can <a target='_blank' href='https://impact.com'>build practical skills at the Partnerships Experience Academy</a> (PXA).</p>`,
  circle1Body: `
    <p>PXA offers free courses and certifications for anyone in the partnerships ecosystem, including:</>
    <ul>
      <li><b>Brands</b> just getting started</li>
      <li><b>Affiliate or partner marketers</b> expanding their programs</li>
      <li><b>Executives</b> seeking to drive growth</li>
      <li><b>Agencies</b> growing their services</li>
      <li><b>Publishers</b> looking for new ways to monetize content</li>
    </ul>
  `,
  circle2Body: `
    <h3>Popular courses include:</h3>
    <ul>
      <li>Getting started with affiliate marketing and partnerships</li>
      <li>Getting started with influencer partnerships</li>
      <li>Getting started with strategic brand-to-brand partnerships</li>
      <li>Affiliate marketing goals, competitive assessment, and commission rates</li>
      <li>Affiliate marketing contracts and policies</li>
      <li>How to recruit and engage affiliate marketing partners</li>
      <li>How to track and optimize affiliate marketing relationships</li>
      <li>How to get started with affiliate media buying</li>
    </ul>
  `,
  buttonText1: "Explore free courses",
  buttonUrl1: "https://impact.com/partnerships-experience-academy/",
  buttonText2: "Explore free courses",
  buttonUrl2: "https://impact.com/partnerships-experience-academy/",
  heroAlt: "the partnerships experience academy",
}))
