import React from "react"
import Page from "src/components/page"
import Markup from "src/components/markup"
import Section from "src/components/section"
import PillButton from "src/components/pill-button"
import { useTrainingStore } from "src/state/training"
import { greyCircle2, redCircle2, blueCircle2 } from "src/components/svgs"
import hero from "src/images/training-hero.png"

const TrainingPage = () => {
  const {
    meta,
    title,
    body,
    circle1Body,
    circle2Body,
    buttonText1,
    buttonUrl1,
    buttonText2,
    buttonUrl2,
    heroAlt,
  } = useTrainingStore()

  return (
    <Page {...meta}>
      <Section className="section-1">
        <div className="left parallax-3">
          <h2 className="title">{title}</h2>
          <div className="body">
            <Markup>{body}</Markup>
          </div>
          <a className="button-1" href={buttonUrl1} target="_blank" rel="noreferrer">
            <PillButton className="white-red">{buttonText1}</PillButton>
          </a>
          <div className="mobile-hero">
            <div className="bg">
              <Markup>{greyCircle2}</Markup>
            </div>
            <img src={hero} alt={heroAlt} />
          </div>
          <div className="circle-1 parallax-2">
            <Markup>{redCircle2}</Markup>
            <div className="content">
              <Markup>{circle1Body}</Markup>
            </div>
          </div>
          <div className="circle-2 parallax-0">
            <Markup>{blueCircle2}</Markup>
            <div className="content">
              <div className="circle-body">
                <Markup>{circle2Body}</Markup>
              </div>
              <a className="button-2" href={buttonUrl2} target="_blank" rel="noreferrer">
                <PillButton className="white-red">{buttonText2}</PillButton>
              </a>
            </div>
          </div>
        </div>
        <div className="right parallax-n3">
          <div className="bg">
            <Markup>{greyCircle2}</Markup>
          </div>
          <img src={hero} alt={heroAlt} />
        </div>
      </Section>
    </Page>
  )
}

export default TrainingPage
